import React from 'react'
import { Link } from "@reach/router";
import { IoIosArrowRoundBack } from "react-icons/io"; 
import illustrationPnf from '../img/page-not-found.svg'

const PageNotFound = () => {
    return (
        <div className="wd-404">
            <div className="wd-404__img-box">
                <img className="wd-404__img" src={illustrationPnf} alt="404"/>
            </div>
            <div className="wd-404__link">
                <Link to="/" className="wd-button wd-button--1"> 
                    <IoIosArrowRoundBack className="wd-button__icon wd-button__icon--3"/> go back to home page
                </Link>
            </div>   
        </div>
    )
} 

export default PageNotFound